<template>
  <b-overlay
    :show="showLoading"
    variant="dark"
    :opacity="0.25"
    rounded="sm"
  >
    <!-- Preview Image Logo -->
    <b-modal
      v-model="showPreviewImage"
      hide-header
      :hide-header-close="false"
      hide-footer
    >
      <template #default="{ close }">
        <div class="text-center">
          <div class="d-flex justify-content-end">
            <b-button
              @click="close"
              style="border-radius: 50%; padding: 5px"
              size="sm"
              variant="primary"
              class="close-button mb-1"
            >
              <feather-icon
                icon="XIcon"
                size="18"
              />
            </b-button>
          </div>
          <b-img :src="selectedImage" fluid alt="Preview Image" />
        </div>
      </template>
    </b-modal>

    <validation-observer ref="formRef">
      <b-form @submit.prevent>
        <b-card class="mb-1">
          <!-- form -->
          <b-row>
            <b-form-file
              ref="refLogo"
              v-model="form.logo"
              accept=".jpg, .png"
              :hidden="true"
              plain
              @input="() => handleUpload('HEADER')"
            />
            <b-form-file
              ref="refFooterImage"
              v-model="form.footerImage"
              accept=".jpg, .png"
              :hidden="true"
              plain
              @input="() => handleUpload('FOOTER')"
            />

            <b-col md="12">
              <label class="mr-1" style="font-weight: 550">{{ $t('apps.setting.singular.logoHeader') }}</label>
              <b-media no-body>
                <b-media-aside>
                  <b-link
                    @click="() => form.logoUrl ? showPreviewImgModal('HEADER') : $refs.refLogo.$el.click()"
                  >
                    <b-avatar
                      rounded
                      ref="refPreviewLogo"
                      v-if="form.logoUrl"
                      :src="form.logoUrl"
                      size="5rem"
                    />
                    <b-avatar
                      rounded
                      v-else
                      :text="defaultNoImage"
                      size="5rem"
                    />
                  </b-link>
                  <!--/ avatar -->
                </b-media-aside>

                <b-media-body class="mt-75 ml-75">
                  <!-- upload button -->
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="() => $refs.refLogo.$el.click()"
                  >
                    Upload
                  </b-button>
                  <!--/ upload button -->

                  <!-- reset -->
                  <b-button
                    variant="outline-secondary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="() => removeUploaded('HEADER')"
                  >
                    Reset
                  </b-button>
                  <!--/ reset -->
                  <b-card-text>Allowed JPG or PNG. Max size of 800kB</b-card-text>
                </b-media-body>
              </b-media>
            </b-col>

            <b-col md="12"> <hr> </b-col>

            <b-col md="12" class="mb-2">
              <label class="mr-1" style="font-weight: 550">{{ $t('apps.setting.singular.footerImage') }}</label>
              <b-media no-body>
                <b-media-aside>
                  <b-link
                    @click="() => form.footerImageUrl ? showPreviewImgModal('FOOTER') : $refs.refFooterImage.$el.click()"
                  >
                    <b-avatar
                      rounded
                      ref="refPreviewLogo"
                      v-if="form.footerImageUrl"
                      :src="form.footerImageUrl"
                      size="5rem"
                    />
                    <b-avatar
                      rounded
                      v-else
                      :text="defaultNoImage"
                      size="5rem"
                    />
                  </b-link>
                  <!--/ avatar -->
                </b-media-aside>

                <b-media-body class="mt-75 ml-75">
                  <!-- upload button -->
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="() => $refs.refFooterImage.$el.click()"
                  >
                    Upload
                  </b-button>
                  <!--/ upload button -->

                  <!-- reset -->
                  <b-button
                    variant="outline-secondary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="() => removeUploaded('FOOTER')"
                  >
                    Reset
                  </b-button>
                  <!--/ reset -->
                  <b-card-text>Allowed JPG or PNG. Max size of 800kB</b-card-text>
                </b-media-body>
              </b-media>
            </b-col>

            <b-col md="12">
              <!-- Footer Description -->
              <b-form-group
                :label="$t('apps.setting.singular.footerDescription')"
                label-for="remark"
              >
                <b-form-textarea
                  id="remark"
                  size="sm"
                  v-model="form.remark"
                  name="remark"
                  placeholder="Your description..."
                />
              </b-form-group>
              <!--/ Footer Description -->
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <b-row>
            <b-col md="12" class="mb-2 mt-1">
              <!-- Footer Layout -->
              <div class="mb-1">
                <label class="mr-1" style="font-weight: 550">{{ $t('apps.setting.singular.footerLayout') }}</label>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="() => swapPositionLayout()"
                >
                  Swap Position
                </b-button>
              </div>
              <b-card class="border-secondary bg-light">
                <div v-if="!isSwapped">
                  <div class="h5 mb-1 user-select-none">{{ footerRemarkInLayout }}</div>
                  <div>
                    <b-avatar v-if="form.footerImageUrl"
                      rounded
                      ref="refPreviewLogo"
                      :src="form.footerImageUrl"
                      size="5rem"
                    />
                    <b-avatar v-else rounded size="5rem">
                      <span class="display-6 font-weight-normal text-white user-select-none">{{ footerImageInLayout }}</span>
                    </b-avatar>
                  </div>
                </div>

                <div v-else>
                  <div>
                    <b-avatar v-if="form.footerImageUrl"
                      rounded
                      ref="refPreviewLogo"
                      :src="form.footerImageUrl"
                      size="5rem"
                    />
                    <b-avatar v-else rounded size="5rem">
                      <span class="display-6 font-weight-normal text-white user-select-none">{{ footerImageInLayout }}</span>
                    </b-avatar>
                  </div>
                  <div class="h5 mt-1 mb-0 user-select-none">{{ footerRemarkInLayout }}</div>
                </div>
              </b-card>
              <!--/ Footer Layout -->
            </b-col>

            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
              <b-button v-if="hasConfig"
                variant="primary"
                type="submit"
                @click="() => handleUpdate()"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ $t('globalActions.update') }}
              </b-button>

              <b-button v-else
                variant="primary"
                type="submit"
                @click="() => handleSubmit()"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ $t('globalActions.create') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>

      </b-form>
    </validation-observer>
  </b-overlay>
</template>


<script>
import {
  BForm,
  BFormGroup,
  BFormFile,
  BRow,
  BCol,
  BCard,
  BButton,
  BMedia,
  BMediaAside,
  BFormTextarea,
  BMediaBody,
  BLink,
  BAvatar,
  BCardText,
  BOverlay,
  BModal,
  BImg
} from 'bootstrap-vue'
// import { ref, onMounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import useHttp from '@/comp-functions/useHttp'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useFormResource from '@/comp-functions/useFormResource'


export default {
  components: {
    BForm,
    BFormGroup,
    BFormFile,
    BRow,
    BCol,
    BCard,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BAvatar,
    BCardText,
    BFormTextarea,
    ValidationObserver,
    BOverlay,
    BModal,
    BImg
  },


  setup () {
    const { get } = useFormResource({})
    const { toast, $post, $put } = useHttp()

    return {
      get,
      toast,
      $post,
      $put,
    }
  },


  data () {
    return {
      form: {
        logoUrl: null,
        logo: null,
        footerImageUrl: null,
        footerImage: null,
        remark: null,
      },
      configResult: {},
      configType: 'SALESINVOICE',
      showLoading: false,
      showPreviewImage: false,
      selectedImage: null,
      isSwapped: false,
      showRemark: true,
      showLogo: true,
      hasConfig: false,
    }
  },


  computed: {
    apiBaseUrl () {
      return `${process.env.VUE_APP_BASE_API_URL}`.replaceAll('/api/', '/')
    },

    defaultNoImage () {
      return (
        (this.$store.state.user.currentCompany || { name: 'A' })
        .name || 'A'
      ).charAt(0)
    },

    footerRemarkInLayout () {
      return this.form.remark || '(Your description will show here...)'
    },

    footerImageInLayout () {
      return '(Your footer image will show here...)'
    },

    getForms () {
      const tempForm = new FormData()
      tempForm.append('type', this.configType)
      tempForm.append('logo', this.form.logo)
      tempForm.append('footer_remark', this.form.remark)
      tempForm.append('footer_image', this.form.footerImage)
      tempForm.append('footer_first_position', this.isSwapped ? 'IMAGE' : 'REMARK')

      return tempForm
    }
  },


  async mounted () {
    await this.getConfig()
  },


  methods: {
    handleUpload (type = 'HEADER') {
      const reader = new FileReader()

      if (type === 'HEADER') {
        if (this.form.logo) {
          reader.readAsDataURL(this.form.logo)
          reader.onload = () => {
            this.form.logoUrl = reader.result
          }
        }
      } else if (type === 'FOOTER') {
        if (this.form.footerImage) {
          reader.readAsDataURL(this.form.footerImage)
          reader.onload = () => {
            this.form.footerImageUrl = reader.result
          }
        }
      }
    },

    removeUploaded (type = 'HEADER') {
      if (type === 'HEADER') {
        this.form.logo = null
        this.form.logoUrl = null
      } else if (type === 'FOOTER') {
        this.form.footerImage = null
        this.form.footerImageUrl = null
      }
    },

    showPreviewImgModal (type = 'HEADER') {
      this.showPreviewImage = !this.showPreviewImage

      if (type === 'HEADER') {
        this.selectedImage = this.form.logoUrl
      } else if (type === 'FOOTER') {
        this.selectedImage = this.form.footerImageUrl
      }
    },

    swapPositionLayout () {
      this.isSwapped = !this.isSwapped
    },

    async convertUrlToFile(name, url) {
      const response = await fetch(url)
      const blob = await response.blob()
      const file = new File([blob], name, { contentType: 'image/png' })

      return file
    },

    callbackSuccess () {
      this.toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success',
          icon: 'BellIcon',
          variant: 'success',
          text: `${this.$t('feedback.success')} ${this.$t('feedback.updated')} Sales Invoice Config.`
        }
      })
    },

    async getConfig () {
      this.showLoading = true
      await this.get({ url: `setting/printing-config/${this.configType}` })
        .then(async (result) => {
          if (result) {
            this.configResult = result
            this.hasConfig = true
            this.form.remark = result.footer_remark
            this.isSwapped = result.footer_first_position !== 'REMARK'

            if (result.logo) {
              this.form.logo = await this.convertUrlToFile(result.logo, `${this.apiBaseUrl}${result.logo_path}/${result.logo}`)
            }
            if (result.footer_image) {
              this.form.footerImage = await this.convertUrlToFile(result.footer_image, `${this.apiBaseUrl}${result.footer_image_path}/${result.footer_image}`)
            }

            this.handleUpload('HEADER')
            this.handleUpload('FOOTER')
          } else {
            this.hasConfig = false
            this.configResult = {}
          }
        })
        .catch((err) => { console.log(err) })
        .finally(() => { this.showLoading = false })
    },

    async handleSubmit () {
      this.showLoading = true
      await this.$swal({
        title: `${this.$t('confirm.createNew')} Sales Invoice's Config ?`,
        icon: 'question',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      }).then(async () => {
        const formData = this.getForms

        await this.$post({
          data: formData,
          url: `setting/printing-config/${this.configType}`
        })
          .then(() => this.callbackSuccess())
          .catch(err => console.log(err))
          .finally(() => this.showLoading = false)
      })
    },

    async handleUpdate () {
      this.showLoading = true
      await this.$swal({
        title: `${this.$t('confirm.updateThis')} Sales Invoice's Config ?`,
        icon: 'question',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      }).then(async () => {
        const formData = this.getForms

        await this.$put({
          data: formData,
          url: `setting/printing-config/${this.configType}`
        })
          .then(() => this.callbackSuccess())
          .catch(err => console.log(err))
          .finally(() => this.showLoading = false)
      })
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
